@import '../../../RbKit/config';

.status {
    display: block;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background: #b0b0b0;

    &--1 {
        background-color: $colorSuccess;
    }
    &--2 {
        background-color: $colorError;
    }
}

.timeline {
    max-height: calc(100vh - 300px);
    overflow: auto;
    padding-right: 32px;
    position: relative;
    text-align: right;

    .timelineInner {
        margin-right: 32px;
        position: relative;

        &:after {
            background-color: #d3d3d3;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: -33px;
            width: 2px;
            z-index: 1;
        }
    }

    .timelineItem {
        margin-bottom: 32px;
        position: relative;

        &:after, &:before {
            background-color: #f5f6fa;
            border-radius: 100%;
            content: '';
            display: block;
            height: 32px;
            position: absolute;
            top: -4px;
            right: -39px;
            width: 32px;
            z-index: 2;
        }

        &:before {
            border: solid 2px $colorRocheBlue;
            top: 4px;
            height: 10px;
            width: 10px;
            z-index: 3;
        }

        &.timelineItemRed:before {
            background-color: $colorRocheRed;
            border: solid 2px $colorRocheRed;
        }

        .timelineItemContent {
            font-size: 14px;
            text-align: right;

            > span {
                margin-right: 5px;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    background-color: $colorBlue10;
                    display: block;
                    margin-right: 3px;
                    font-size: 12px;
                    padding: 5px 8px;
                    line-height: 1;
                    margin-bottom: 3px;

                    &:last-child {
                        margin-right: 0;

                        &:after {
                            content: '',
                        }
                    }
                }
            }
        }
        .timelineItemMeta {
            font-size: 12px;
            color: $colorGrey30;
            margin-top: 1px;
        }
    }
}
